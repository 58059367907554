/* You can add global styles to this file, and also import other style files */

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.po-page-login-panel {
    background-color: white;
}

.reverse_style {
    border-color: black;
    color: black
}

.limitModal .po-modal-content,
.limitModal .po-modal-body {
    max-width: 1340px;
    width: 99%;
}

.limitModal .po-modal-body {
    height: 450px;
    overflow-y: scroll;
}


/* Tamanho padrão da Scrollbar */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 7px;
}

 ::-webkit-scrollbar-thumb {
    background: rgb(60, 0, 255);
    border-radius: 10px;
}

 ::-webkit-scrollbar-thumb:hover {
    background: grey;
}

.po-table-container-fixed-inner::-webkit-scrollbar,
.po-table-main-container::-webkit-scrollbar {
    border-radius: 2px;
    height: 15px;
    opacity: .5;
    width: 4px;
}

.scroller {
    overflow-y: hidden;
    scrollbar-color: rgb(12, 154, 190) #C2D2E4;
}

.scroller::-webkit-scrollbar-track-piece {
    background-color: rgb(12, 154, 190);
}

.scroller::-webkit-scrollbar-thumb:vertical {
    height: 10px;
    background-color: rgb(12, 154, 190);
}

.po-table .po-table-header,
.po-table .po-table-column {
    padding: 0 8px;
}

#modalUser .po-modal-sm {
    width: 32%;
}

#buttonInfo .po-button {
    border: none;
    height: 95%;
}

#someScroll .po-table-container-fixed-inner {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.widgetNovoBanner .po-widget {
    height: 100%;
}

#novaImgBanner .po-container {
    width: 80%;
    margin: 0 auto 20px;
}

#novaImgBanner .po-container .po-widget-body span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

#novaImgBanner .po-container img {
    max-height: 450px;
}

.img-fit {
    width: 100%;
    /* height: auto; */
}

#info-upload .po-info-value,
.info-upload .po-info-value {
    padding: 10px;
    font-size: 12px;
    line-height: 12px;
    min-height: 12px;
    color: #0c9abe;
    font-weight: bold;
}

.info-upload .po-info-value {
    font-size: 13px;
}

#info-upload .po-info-container-content,
.info-upload .po-info-container-content {
    padding: 6px 0;
}

#modalImpressao .po-modal-content.po-modal-auto {
    max-width: 65%;
}

.po-menu-logo {
    margin: auto 0;
    max-height: 48px;
    max-width: 224px !important;
    padding: 8px 16px;
}

#dividerFonteMaior .po-divider-label {
    font-family: NunitoSans-Bold, sans-serif;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #29b6c5;
}

#tituloWidget .po-widget-header,
.po-widget-primary .po-widget-header {
    font-family: NunitoSans-Bold, sans-serif;
    font-size: 15px;
    line-height: 24px;
    height: auto;
    padding: 13px 16px;
    text-align: left;
    color: white;
    background-color: #0c9abe;
}

.po-widget-body,
.po-widget-header {
    color: #4a5c60;
}

.imgProdutos .img-fit {
    max-width: 100%;
    max-height: 250px;
    min-height: 250px;
    text-align: center;
    /* height: auto;  */
}

.imgProdutos .img-fit img {
    max-width: 100%;
    max-height: inherit;
}

#hideSecondScrollTable .po-table-container-fixed-inner {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.img-table {
  display: flex;
  align-items: center;
  width: 100%;
  height: 10rem;
  padding: 1%;
  object-fit: contain;
}
